<template>
	<div class="row">
		<e-select
			id="horse_cats"
			class="mb-3"
			v-model="selected_categorie"
			track-by="categorie_id"
			label="categorie_libelle"
			:placeholder="$t('horse.categorie_cheval')"
			:options="horse_categories"
			:searchable="true"
			:allow-empty="false"
			:loading="working"
			:show-labels="false"
			:multiple="true"
		>
			<template slot="noOptions">{{ $t('categorie.aucune_categorie') }}</template>
		</e-select>
		<div class="text-center mt-3">
            <button @click="addCategorie" class="btn btn-primary rounded-pill">
                <font-awesome-icon :icon="['far', 'save']" /> {{ $t('global.valider') }}
            </button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import HorseMixin from "@/mixins/Horse.js";

	export default {
		name: 'AjoutCategorie',
		mixins: [Shutter, ShutterTriggers, HorseMixin],
		props: {
			horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
		},
		data () {
			return {
				horse_categories: [],
            	selected_categorie: [],
				working: true,
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.horse_categories = await this.getAllCategorie()
				this.working = false
			},

			addCategorie() {
				let tab_promises = []

				this.selected_categorie.forEach(cat => {
					this.horses_ids.forEach(horse_id => {
						tab_promises.push(this.addHorseCategorie(horse_id, cat.categorie_id))
					})
				})

				Promise.all(tab_promises)
	                .then(() => {
	                    this.successToast("toast.info_modif_succes")
	                    if(this.next.length > 0) {
		                    const triggered = this.next[0]
		                    this.next.shift()
		                    this.shutterPanel().close('add_categorie')
		                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
		                } else { 
							let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
		                    this.$router.push({ name: 'HorseFichePlanning', params: {horse_id} })
		                }
	                })
	                .catch((e) => {
	                    this.failureToast("toast.info_modif_failed")
	                }).finally(() => {
	                    this.loading = false
	                    this.spinner = false
	                })
			},
		}
	}

</script>